.TextPart {
	img {
		@extend .Image;
		height: auto!important;
	}
	p {
		@extend .Text;
		@extend .Text--medium;
		@extend .Text--light;
		@extend .Text--spacing;
	}

	table {
		@extend .table;
		@extend .table-striped;
	}
}

.TextPart--justify {
	text-align: justify;
}
