.Headline {
	font-size: $font-size-headline--default;
	font-weight: normal;
	margin: 0 0 25px;
}

.Headline--spacingLarge {
	margin-bottom: 50px;
}

.Headline--spacingSmall {
	margin-bottom: 15px;
}

.Headline--noSpacing {
	margin: 0;
}

.Headline--extraSmall {
	font-size: $font-size-headline--extraSmall;
}

.Headline--small {
	font-size: $font-size-headline--small;
}

.Headline--large {
	font-size: $font-size-headline--large;
}

.Headline--medium {
	font-size: $font-size-headline--medium;
}

.Headline--light {
	font-size: $font-size-headline--large;
}

.Headline--thin {
	font-weight: 300;
}

.Headline--dark {
	color: $dark;
}

.Headline--inversed {
	color: $white;
}

.Headline--strong {
	font-weight: bold;
}


@media only screen and (max-width: $screen-xs-max) {

	.Headline {
		font-size: $font-size-headline--default * 0.95;
	}

	.Headline--extraSmall {
		font-size: $font-size-headline--extraSmall * 0.95;
	}

	.Headline--small {
		font-size: $font-size-headline--small * 0.95;
	}

	.Headline--large {
		font-size: $font-size-headline--large * 0.7;
	}

	.Headline--medium {
		font-size: $font-size-headline--medium * 0.8;
	}
}
