/* ==========================================================================
   Forms - Widgets
   ========================================================================== */

/* Hidden
   ========================================================================== */
.form-widget--hidden {
    display: none;
}


/* Select
   ========================================================================== */
.form-widget--select {
}

/* Choices
   ========================================================================== */
.form-widget--choices {
}

.form-widget--choices--filled {
}


/* Widget - File Upload
   ========================================================================== */
.form-widget--file-upload {
}

/* Text area
   ========================================================================== */



/*doc
---
title: Form Widgets
name: 05_widgets
category: Forms
---


```html_example
<div class="form-widget--select" id="formwidget">
    <i class="icon icon--triangle-down select__icon"></i>
    <select id="formwidget-value" name="form[formwidget][formwidget-value]" required="required" class="form-control form-control--select">
        <option value="0">I want to make a website with the Kunstmaan bundles</option>
        <option value="1">I'm testing the website</option>
        <option value="2">I want to get a quote for a website built by Kunstmaan</option>
    </select>
    <span class="form-bar"></span>
</div>
```
*/
