/* ==========================================================================
   Helpers

   Never add styles directly to this file - set up imports
   ========================================================================== */


/* Placeholders
   ========================================================================== */
// @import "placeholders/..";


/* Mixins
   ========================================================================== */
// @import "mixins/..";


.z1 {
	position: relative;
	z-index: 1;
}

.stepV {
	clear: both;
	display: block;

	&--extraSmall {
		height: 10px;
	}

	&--small {
		height: 25px;
	}
	&--medium {
		height: 50px;
	}
	&--large {
		height: 100px;
	}
}
