.Hr {
    border-top: 2px solid $primary;
    margin: 25px 0;
    width: 100%;
}

.Hr--half {
    width: 33%;
}


.Hr--light {
    border-top: 1px solid #eeeeee;
}

.Hr--noSpacing {
    margin: 0;
}
