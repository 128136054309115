/* ==========================================================================
   Bodycopy

   This file is exclusively intended for setting up variables
   Never add styles directly to this file
   ========================================================================== */

/* Line-height
   ========================================================================== */
$line-height--base:     1.5;

$line-height--small:    1.2;
$line-height--medium:   1.5;


/* Font sizes
   ========================================================================== */
$font-size--base:               15px;
$font-size--small:              12px;
$font-size--medium:             16px;
$font-size--big:                20px;

$font-size-headline--extraSmall: 18px;
$font-size-headline--small:      24px;
$font-size-headline--default:    30px;
$font-size-headline--large:      50px;
$font-size-headline--medium:     36px;
