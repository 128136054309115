/* ==========================================================================
   Links
   ========================================================================== */

/* Shared
   ========================================================================== */
a {
    color: $link-color;
    text-decoration: underline;

    &:hover, &:focus {
        text-decoration: none;
        color: $link-color--hover;
    }
}


/*doc
---
title: Links
name: 02_links
category: Blocks
---

```html_example

<p>
    <a href="#">Link</a>
</p>

```
*/
