/* ==========================================================================
   Pageparts - Header pp
   ========================================================================== */

/*doc
---
title: header-pp
name: 01_header-pp
category: Pageparts
---

```html_example
    <h1 id="header-h1">
        Header h1
    </h1>
    <h2 id="header-h2">
        Header h2
    </h2>
    <h3 id="header-h3">
        Header h3
    </h3>
    <h4 id="header-h4">
        Header h4
    </h4>
    <h5 id="header-h5">
        Header h5
    </h5>
    <h6 id="header-h6">
        Header h6
    </h6>
```

*/
