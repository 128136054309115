/* ==========================================================================
   Forms - Form control
   ========================================================================== */

/* General
   ========================================================================== */
.form-control {
    display: block;
    width: 100%;
    max-width: 100%;
    min-height: 40px;
    padding: 14px 15px;
    font-size: 18px;
    border-radius: $button-border-radius;

    border: 2px solid $light;
    background: $light;

    transition: border .3s ease;


    &:focus {
        outline: none;
        border: 2px solid rgba($primary, 0.5);
    }

}

.form-group--has-error {
  .form-control {
    &:focus {
        outline: none;
        border: 2px solid darken($red, 10%);
    }
  }
}

/* Input
   ========================================================================== */
.form-control--input {}


/* Textarea
   ========================================================================== */
.form-control--textarea {
  resize: none;
}


/* Select
   ========================================================================== */
.form-control--select {
}


/*doc
---
title: Form Control
name: 02_form-control
category: Forms
---

<strong>Input</strong>

```html_example
<input type="text" id="fc1" name="fc1" class="form-control form-control--input">
```

<strong>Textarea</strong>

```html_example
<textarea id="fc2" name="fc2" class="form-control form-control--textarea"></textarea>
```

<strong>Select</strong>

```html_example
<select id="fc3" name="fc3" class="form-control form-control--select">
    <option>1</option>
    <option>2</option>
    <option>3</option>
</select>
```


*/
