/* ==========================================================================
   Base
   ========================================================================== */

/* Box model
   ========================================================================== */
* {
    box-sizing: border-box;
}

*:before, *:after {
    box-sizing: border-box;
}


/* Html & body
   ========================================================================== */
html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
}

body {
    background-color: $white;
    min-height: 100%;
}
