/* ==========================================================================
   Lists
   ========================================================================== */

/* Reset
   ========================================================================== */
.list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}




/*doc
---
title: Lists
name: 05_lists
category: Blocks
---

<strong>Unorderd list</strong>

```html_example
<ul>
    <li>Unorderd list</li>
    <li>Unorderd list</li>
    <li>Unorderd list</li>
    <li>Unorderd list</li>
</ul>
```

<strong>Orderd list</strong>

```html_example
<ol>
    <li>Orderd list</li>
    <li>Orderd list</li>
    <li>Orderd list</li>
    <li>Orderd list</li>
</ol>
```

<strong>Reset</strong>

```html_example
<ul class="list--reset">
    <li>Unorderd list</li>
    <li>Unorderd list</li>
    <li>Unorderd list</li>
    <li>Unorderd list</li>
</ul>
```
*/
