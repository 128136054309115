.Button {
	display: inline-block;
	border: 0;
	font-size: $font-size--medium;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: $button-border-radius;
	padding: $button-padding;
	background: $link-color;
	text-decoration: none;
	color: $white;
	transition: background .3s ease;

	&:hover,
	&:focus {
		background: darken($link-color, 5%);
		color: $white;
	}

	&:active {
		background: darken($link-color, 10%);
		color: $white;
	}
}


.Button--small {
	padding: $button-padding--small;
	font-size: $font-size--base - 1;
}


.btn {
	@extend .Button;
}

.btn[type="submit"]{
	width: 100%;
	margin-top: 15px;
}


@media only screen and (max-width: 500px) {

	.Button,
	.btn {
		width: 100%;
	}
}
