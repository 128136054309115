/* ==========================================================================
   Footer - Main footer
   ========================================================================== */

/* General
   ========================================================================== */
.main-footer {
    padding-top: .5em;
    padding-bottom: .5em;
}

