.pagination {
	text-align: center;

	ul {
		@extend .list-reset;
		display: inline-block;
	}

	li {
		margin: 0 5px;
		float: left;
		@extend .Text;
		@extend .Text--light;
	}

	a {
		@extend .Link;
		@extend .Link--underlineOnHover;
	}
}
