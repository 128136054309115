/* ==========================================================================
   Footer - Sub-footer
   ========================================================================== */

/* Inner
   ========================================================================== */
.sub-footer__inner {
    @include clearfix;

    padding: 1em 0;

}


/* Info
   ========================================================================== */
.sub-footer__info {
    margin: 0;

    font-size: .75rem;
    text-align: center;

    @media(min-width: $screen-xs-min) {
        float: left;
    }
}
.sub-footer__info__link {
    display: inline-block;

    color: $text-color;

    &:hover, &:focus, &:active {
        color: $text-color;
    }
}


/* Credentials
   ========================================================================== */
.sub-footer__credentials {
    font-size: .75rem;
    text-align: center;

    margin: 0;

    @media(min-width: $screen-xs-min) {
        float: right;
    }
}

.sub-footer__credentials__link {
    display: inline-block;
    margin-left: .2rem;

    vertical-align: text-top;
}

.sub-footer__credentials__img {
    display: block;
}
