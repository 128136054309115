$offcanvas-width: 270px;
$offset--right: 270px;

@import "../../../node_modules/js-offcanvas/src/js-offcanvas";


.c-offcanvas {
	background-color: $dark;
	outline: none!important;
}


@media only screen and (min-width: 450px) {
	.c-offcanvas--right {
		width: 320px;
		transform: translate3d(320px,0,0);
	}
	.c-offcanvas-bg.c-offcanvas-bg--push.c-offcanvas-bg--right.is-open {
		transform: translate3d(-320px, 0, 0);
	}
}
