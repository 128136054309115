/* ==========================================================================
   Body Copy
   ========================================================================== */

/* General
   ========================================================================== */
* {
   -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
}

html, body {
    font-family: $font-family--base;
    font-size: $font-size--base;
    line-height: $line-height--base;
    color: $text-color;
}

/*
p {
    margin: 1rem 0;

    @media (min-width: $screen-md-min) {
        margin: 1.5rem 0;
    }
}
*/

p, .pp--text {
    + h1, + h2, + h3, + h4, + h5, + h6 {
        margin-top: 3rem;
    }
}


/* General Modifiers
   ========================================================================== */

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }

// Transformation
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

// Contextual colors
.text-muted {
  //color: $text-muted;
}

.list-noStyle {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
        padding: 0;
        margin-bottom: 3px;
    }
}

/* Intro text
   ========================================================================== */
.text--intro {
    font-size: 1.2rem;
    line-height: 1.3;

}


/*doc
---
title: Bodycopy
name: 02_bodycopy
category: Typography
---
<div class="container exampleOutput no-markup">
    <div class="row">
        <div class="col-md-12">
            <p><strong>Intro text</strong></p>
            <p class="text--intro">
                Text intro -- Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam ab illum voluptatem illo optio, temporibus doloribus, quaerat vitae laboriosam dolorum, sequi deleniti beatae ea molestiae quis, asperiores!
            </p>
            <p><strong>Body text</strong></p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam ab illum voluptatem illo optio, temporibus doloribus, quaerat vitae laboriosam dolorum, sequi deleniti beatae ea molestiae quis, asperiores! Voluptate, ea, ipsam.
                Reiciendis ad architecto ea veritatis harum corporis quo fugit ut cumque, nostrum, dolores sed quia quisquam quos provident. Cupiditate aliquid harum, commodi laborum culpa fugiat, doloribus id eveniet asperiores repellat?
                Nostrum, exercitationem. Pariatur, ut saepe quae aperiam repudiandae animi, aspernatur facilis ipsum? Voluptatibus, impedit id libero eos ex magnam? Amet vero provident aliquid quas molestiae molestias ipsum, consequuntur. Deleniti, neque?
                Facere recusandae natus, reprehenderit ullam sunt temporibus earum ex accusantium vel quod. Provident laudantium tempore quo soluta adipisci, beatae autem, aut ex? Eligendi, dolores officia. Vero atque, repellat similique iste.
                Repellendus molestias odio exercitationem. Quasi, eius aperiam illo nulla vero impedit, modi asperiores quam nihil! Dignissimos fuga eveniet aperiam necessitatibus itaque, obcaecati magni doloremque possimus vitae vel quasi officia vero.
                Provident sit itaque modi fugit ad sed ullam, quae sapiente, corporis aut doloribus repellat quidem in aliquid nostrum officia odit, iste assumenda veniam harum qui fuga soluta. In, soluta, corporis!
                Voluptates, nisi, ratione. Temporibus distinctio vero ad eligendi iure assumenda quod sint dignissimos placeat, error ex cupiditate consequuntur ut corporis veniam architecto fugiat ipsa quos, consectetur aperiam autem, alias voluptatum!
                Cumque saepe aspernatur sit exercitationem sequi quae perferendis delectus praesentium, tempora voluptatum voluptate vero animi vitae dolor! Soluta asperiores praesentium omnis mollitia minima doloribus magni dolore blanditiis. Voluptates, deserunt, dolorum.
                Minus tenetur soluta nemo magni hic accusamus voluptatum delectus deleniti nostrum, eius rerum, natus non culpa, minima dolorum consectetur dignissimos quibusdam fuga ut nihil impedit fugiat earum. Incidunt, optio, vel?
                Necessitatibus officiis consequuntur quas eius est praesentium soluta neque obcaecati quos totam repudiandae iste odio velit vero modi dolorem ex, sed sunt aperiam, ducimus minus provident quidem quae similique. Earum.
            </p>
        </div>
    </div>
</div>
*/
