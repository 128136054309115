.Link {
	transition: all .3s ease;
	outline: none;

	&-hamburgerMenu {
		display: inline-block;
		height: 2px;
		width: 20px;
		background-color: #fff;
		position: relative;
		top: -5px;
		margin-left: 10px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 2px;
			background-color: #fff;
			top: -6px;
		}

		&:after {
			top: auto;
			bottom: -6px;
		}
	}
}

.Link--inversed {
	color: $white;

	&:hover,
	&:focus,
	&:active {
		color: $white;
	}
}

.Link--sideMenu {
	font-size: 14px;
	text-transform: uppercase;
	text-decoration: none;
	color: $white;
	opacity: .6;
	font-weight: bold;
	margin-top: 16px;
	display: inline-block;

	&:hover,
	&:focus,
	&:active {
		color: $white;
		opacity: .8;
	}
}

.Link--logo {
	font-size: 16px;
	vertical-align: middle;
	text-decoration: none;
	display: table;
	line-height: 22px;

	.Link-logo,
	.Link-text {
		display: table-cell;
		vertical-align: middle;
	}

	.Link-text {
		padding-left: 15px;
		transition: all .3s ease;
	}

	&:hover, &:focus {

		.Link-text {
			opacity: 0.85;
		}
	}
}

.Link--dark {
	color: $dark;

	&:hover,
	&:focus,
	&:active {
		color: lighten($dark, 5%);
	}
}

.Link--noDecoration {
	text-decoration: none!important;
}


.Link--light {
	color: rgba($white, .6);

	&:hover,
	&:focus,
	&:active {
		color: rgba($white, 1);
	}
}

.Link--underlineOnHover {
	position: relative;
	text-decoration: none;

	&:before {
		content: "";
		position: absolute;
		bottom: -2px;
		left: 50%;
		width: 0%;
		height: 1px;
		background-color: $link-color--hover;
		transition: all .3s ease;
	}

	&.Link--light {
		&:before{
			background-color: $white;
			opacity: 0.9;
		}
	}

	&.Link--active {
		//color: rgba($white, .9);
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			width: 100%;
			transform: translate(-50%, 0);
			opacity: 1;
		}
	}

}
