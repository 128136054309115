/* ==========================================================================
   hr
   ========================================================================== */

hr {
    margin: 2rem 0;

    border: 0;
    border-top: 1px solid $grey;
}

aside {
    hr {
        margin: 1rem 0;
    }
}


/*doc
---
title: Hr
name: 03_hr
category: Blocks
---
```html_example
    <hr>
```
*/
