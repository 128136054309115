/* ==========================================================================
   Forms - Form control label
   ========================================================================== */


/* General
   ========================================================================== */
.form-control-label {
	margin-bottom: 10px;
	font-size: $font-size--medium;
	font-weight: 400;
	display: block;
	@extend .Text--lighten;
}

.form-horizontal > .form-group  > .form-control-label {
	display: none;
}


/*doc
---
title: Label
name: 03_label
category: Forms
---


```html_example
<label class="form-control-label">
    Name
</label>
```
*/
