/* ==========================================================================
   Cookie consent
   ========================================================================== */

.cookie-bar {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    z-index: 500;

    display: none;
    width: 100%;
    padding: 30px;

    background: $dark;

    animation-name: cookieSlide;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.8s;
}


.cookie-bar--visible {
    display: block;
}

@keyframes cookieSlide {
    0% {
        transform: translateY(6rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

/*doc
---
title: Cookie consent
name: 09_cookieconsent
category: Structures
---

```html_example
<div style="height: 400px; width: 100%; position: relative;">
    <div id="cookie-bar" class="cookie-bar" style="position: absolute;">
    <p>
        Deze website gebruikt <br>cookies om uw surf- <br>ervaring makkelijker te maken.
    </p>
    <button type="button" id="cookie-bar__consent-btn" class="btn btn-warning cookie-bar__btn">Doorgaan</button>
    </div>
</div>
```

*/
