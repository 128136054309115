.Section {}

.Section--spacing {
	padding: $section-padding;
}

.Section--spacingSmall {
	padding: $section-padding-small;
}

.Section--spacingExtraSmall {
	padding: $section-padding-extraSmall;
}

.Section--spacingTopLarge {
	padding-top: 200px;
}

.Section--absoluteTop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.Section--spacingSmallMargin {
	margin: $section-padding-small;
}

.Section--backgroundLight {
	background-color: $background-light;
}

.Section--backgroundDark {
	background-color: $background-dark;
}

.Section--backgroundMultiply {
	background-blend-mode: multiply;
}

.Section--backgroundImage {
	background-image: url('http://localhost:8888/cheemo/wp-content/themes/cheemo/img/bg/bg-default.jpg');
	background-position: center center;
	background-size: cover;
}

.Section--backgroundNews {
	background-image: url('http://localhost:8888/cheemo/wp-content/themes/cheemo/img/bg/bg-news.jpg');
}

.Section--backgroundContact {
	background-image: url('http://localhost:8888/cheemo/wp-content/themes/cheemo/img/bg/bg-contact.jpg');
}

.Section--noHrLast {
	&:last-child {
		hr {
			display: none;
		}
	}
}


@media only screen and (max-width: $screen-xs-max) {

	.Section--spacing {
		padding: 75px 0;
	}

	.Section--spacingSmall {
		padding: 40px 0;
	}

	.Section--spacingTopLarge {
		padding-top: 120px;
	}
}
