/* ==========================================================================
   Pageparts - Introtext pp
   ========================================================================== */

/* General
   ========================================================================== */
.introtext-pp {
    max-width: 40rem;
    margin: $default-margin auto;
}

/*doc
---
title: introtext-pp
name: 05_introtext-pp
category: Pageparts
---

```html_example
<div class="introtext-pp text--intro">
    <p>This is some intro text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
</div>
```

*/
