.Image {
	max-width: 100%;
	height: auto;

	&-wrapper {
		display: inline-block;
		position: relative;
		width: 100%;
	}

	&-copy {
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 10px;
		text-align: left;
		color: #000;
		opacity: 1;
		font-size: 12px;
		text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.9);
		font-weight: 300;
	}
}

.Image--responsive {
	width: 100%;
}
.Image--shadow {
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
}
