.SideMenu {
	position: relative;
	height: 100%;

	&-top {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			padding: 0;
			width: 100%;
			border-top: 1px solid rgba(#fff, .1);
		}
	}

	&-title {
		padding: 13px 30px;
	}

	&-link {
		display: block;
		width: 100%;
		padding: 20px 30px;
		color: rgba(#fff, .7);
		font-size: 20px;
		font-weight: 400;
		text-decoration: none;
		transition: color .3s ease;

		svg {
			position: relative;
			top: 2px;
			opacity: .7;
			transition: all .3s ease;
		}

		&:hover, &:focus {
			text-decoration: none;
			color: rgba(#fff, 0.8);

			svg {
				opacity: .8;
			}
		}

		&:active {
			color: rgba(#fff, 1);

			svg {
				opacity: 1;
			}
		}

		outline: none!important;
	}

	&-link--active {
		color: rgba(#fff, 1)!important;
	}

	&-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: auto;
		padding: 40px;
	}
}

@media only screen and (max-width: $screen-xs-max) {

	.SideMenu {

		&-title {
			padding: 15px 30px;
		}

		&-link {
			padding: 15px 30px;
			font-size: 18px;
		}

		&-bottom {
			padding: 0 30px 30px 30px;
		}
	}
}
