.Logo {
	max-width: 80px;
	width: 100%;
	display: inline-block;
	transition: transform .3s ease;
	position: relative;

	&-content {
		display: inline-block;
		width: 100%;
		padding-bottom: 100%;
		background-image: url("#{$general}/logo.png");
		background-size: cover;
		background-position: center center;
		position: relative;
	}

	&-eye {
		position: absolute;
		left: 41%;
		top: 20%;
		height: 18%;
		width: 25%;
		display: inline-block;
		overflow: hidden;
		z-index: 1000;

		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 50%;
			background: #fff;
			animation-name: Logo-eye-animation;
			animation-duration: 4s;
			animation-iteration-count: infinite;
		}

		&:after {
			bottom: 0;
			top: auto;
		}

	}

	&:hover{
		transform: scale(1.1);
		.Logo-eye {
			&:before,
			&:after {
				animation-name: Logo-eye-animation-fast;
				animation-duration: 1s;
				animation-iteration-count: infinite;
			}
		}
	}

	img {
		width: 100%;
		max-width: 100%;
	}
}

.Logo--medium {
	width: 40px;
}

.Logo--small {
	width: 30px;
}


@keyframes Logo-eye-animation {
	0%   { height: 0; }
	94%  { height: 0; }
	97%  { height: 50%; }
	100% { height: 0; }
}

@keyframes Logo-eye-animation-fast {
	0%   { height: 0; }
	80%  { height: 0; }
	90%  { height: 50%; }
	100% { height: 0; }
}
