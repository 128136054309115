.Text {
	margin: 0;
}

.Text--spacing {
	margin-bottom: 10px;
}

.Text--small {
	font-size: $font-size--small;
}

.Text--medium {
	font-size: $font-size--medium;
}

.Text--large {
	font-size: $font-size--big;
}

.Text--light {
	color: #666;
}

.Text--lighten {
	opacity: .8;
}

.Text--inversed {
	color: $white;
}

.Text--thin {
	font-weight: 300;
}

.Text--strong {
	font-weight: bold;
}

.Text--primary {
	color: $primary;
}


@media only screen and (max-width: $screen-xs-max) {

	.Text--small {
		font-size: $font-size--small;
	}

	.Text--medium {
		font-size: $font-size--medium;
	}

	.Text--large {
		font-size: $font-size--big * 0.9;
	}
}
