/* ==========================================================================
   Pageparts - Text pp
   ========================================================================== */

/* General
   ========================================================================== */
.text-pp {
    max-width: 40rem;
    margin: $default-margin 0;

}

/*doc
---
title: text-pp
name: 08_text-pp
category: Pageparts
---

```html_example
<div class="text-pp">
    <p>This is a regular text pagepart. Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing</a> elit, sed do eiusmod tempor incididunt ut <strong>labore et dolore</strong> magna aliqua.</p>
</div>
```

*/
