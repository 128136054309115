.Graph {
	width: 100%;
	overflow: hidden;
	position: relative;
	top: -130px;
	z-index: 0;
	margin-bottom: -50px;

	&-wrapper {
		width: 110%;
		margin-left: -10%;
	}
}

@media only screen and (max-width: 1050px) {
	.Graph {
		top: -90px;
		margin-bottom: -20px;
	}
}

@media only screen and (max-width: 800px) {
	.Graph {
		top: -20px;
		margin-bottom: 30px;
	}
}


@media only screen and (max-width: 560px) {
	.Graph {
		top: 0px;
		margin-bottom: 30px;
	}
}
