/* ==========================================================================
   Sizes

   This file is exclusively intended for setting up variables
   Never add styles directly to this file
   ========================================================================== */


/* Margins
   ========================================================================== */
$default-margin: 1.5rem;


/* Buttons
   ========================================================================== */
$button-border-radius: 4px;
$button-padding: 20px 80px;
$button-padding--small: 13px 40px;

/* Section
   ========================================================================== */
$section-padding: 100px 0;
$section-padding-small: 60px 0;
$section-padding-extraSmall: 30px 0;
